import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faAt, faCalendar, faCrown, faEnvelope, faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AUTH_MODAL_PAGES } from '../../constants/enums';
import { USER_UPDATED_PROFILE, USER_UPDATED_PROFILE_ERROR } from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { useUpdateUserMutation } from '../../store/api';
import { setAuthModalPage } from '../../store/modalsSlice';
import { subtractYearsFromDate } from '../../utils/dateTime';
import analyticsEvent from '../../utils/logEvent';
import { serializePlace } from '../../utils/serialize/place';
import LocationInput from './LocationInput';

interface Props {
  closeModal: () => void;
  isSignUp?: boolean;
}
export default function UserProfileForm(props: Props) {
  const { closeModal, isSignUp = false } = props;
  const { user } = useTypedSelector((state) => state);

  const [birthdayValue, setBirthdayValue] = useState<Date | null>(
    user.birthday ? moment(user.birthday, 'YYYY-MM-DD').toDate() : null,
  );
  const [locationInputValue, setLocationInputValue] = useState<string>(
    user.homeLocation?.formatted_address || '',
  );
  const [homePlace, setHomePlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [instagramHandleValue, setInstagramHandleValue] = useState<string>(
    user.instagramHandle || '',
  );
  const [firstNameValue, setFirstNameValue] = useState<string>(user.firstName || '');
  const [lastNameValue, setLastNameValue] = useState<string>(user.lastName || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const dispatch: any = useAppDispatch();
  const t = useTranslations('Global.auth.profile');
  const [updateUser, result] = useUpdateUserMutation();

  const router = useRouter();

  const updateUserProfile = async () => {
    setLoading(true);

    try {
      const userData = {
        ...(!!firstNameValue && { firstName: firstNameValue }),
        ...(!!lastNameValue && { lastName: lastNameValue }),
        ...(!!birthdayValue && { birthday: moment(birthdayValue).format('YYYY-MM-DD') }),
        ...(!!homePlace && { homeLocation: serializePlace(homePlace) }),
        ...(!!instagramHandleValue && { instagramHandle: instagramHandleValue }),
      };

      const response = await updateUser(userData);

      if ('data' in response) {
        if (isSignUp) {
          analyticsEvent(USER_UPDATED_PROFILE, {
            birthday_updated: !!birthdayValue,
            location_updated: !!homePlace,
            instagram_handle_updated: !!instagramHandleValue,
            origin_component: 'auth_modal',
          });
          dispatch(setAuthModalPage(AUTH_MODAL_PAGES.SIGN_UP_SUCCESS));
        } else {
          analyticsEvent(USER_UPDATED_PROFILE, {
            birthday_updated: !!birthdayValue,
            location_updated: !!homePlace,
            instagram_handle_updated: !!instagramHandleValue,
            origin_component: 'profile_modal',
          });
          closeModal();
        }
      }

      if ('error' in response) {
        setLoading(false);
        if (isSignUp) {
          analyticsEvent(USER_UPDATED_PROFILE_ERROR, { error: response.error });
        }
        setFormError(t('form.error'));
        throw response.error;
      }
    } catch (error) {
      setLoading(false);
      if (isSignUp) {
        analyticsEvent(USER_UPDATED_PROFILE_ERROR, {
          error: error,
          origin_component: 'auth_modal',
        });
      } else {
        analyticsEvent(USER_UPDATED_PROFILE_ERROR, {
          error: error,
          origin_component: 'profile_modal',
        });
      }
      setFormError(t('form.error'));
      throw error;
    }
  };

  if (loading) {
    return <Spinner m="auto" mt="6rem" color="pink.500"></Spinner>;
  }

  return (
    <Box>
      <form onSubmit={updateUserProfile}>
        <Flex gap={6} sx={{ flexDirection: 'row' }}>
          <FormControl variant="floating" id="firstName" my={0}>
            <InputLeftElement ml={-1} pointerEvents="none">
              <FontAwesomeIcon color="gray.700" icon={faCrown} size="lg" />
            </InputLeftElement>
            <Input
              value={firstNameValue}
              type="text"
              variant="flushed"
              placeholder=" "
              onChange={(e) => setFirstNameValue(e.target.value)}
              maxLength={30}
            />
            <FormLabel>{t('form.firstNameLabel')}</FormLabel>
          </FormControl>
          <FormControl variant="floating" id="lastName" my={0}>
            <Input
              value={lastNameValue}
              type="text"
              variant="flushed"
              placeholder=" "
              onChange={(e) => setLastNameValue(e.target.value)}
              maxLength={30}
            />
            <FormLabel>{t('form.lastNameLabel')}</FormLabel>
          </FormControl>
        </Flex>

        <FormControl variant="floating" opacity="60%" id="email">
          <InputLeftElement ml={-0.5}>
            <FontAwesomeIcon color="gray.700" icon={faEnvelope} size="lg" />
          </InputLeftElement>
          <Input value={String(user.email)} variant="flushed" isDisabled={true} />
          <Tooltip hasArrow label="Email addresses are not editable" placement="top">
            <InputRightElement mr={3} mt={2}>
              <FontAwesomeIcon color="gray.700" icon={faLock} size="lg" />
            </InputRightElement>
          </Tooltip>
        </FormControl>

        <LocationInput
          id="homeLocation"
          inputLabel={t('form.homeLocationLabel')}
          initialValue={locationInputValue}
          updateInputValueState={setLocationInputValue}
          updateSelectedPlaceState={setHomePlace}
        />

        <FormControl variant="floating" id="instagramHandle">
          <InputLeftElement pointerEvents="none">
            <FontAwesomeIcon color="gray.700" icon={faAt} size="lg" />
          </InputLeftElement>
          <Input
            value={instagramHandleValue}
            type="text"
            variant="flushed"
            placeholder=" "
            onChange={(e) => setInstagramHandleValue(e.target.value)}
            maxLength={31}
          />
          <FormLabel>{t('form.instagramHandleLabel')}</FormLabel>
        </FormControl>

        <FormControl variant="floating" id="birthday">
          <InputLeftElement pointerEvents="none">
            <FontAwesomeIcon color="gray.700" icon={faCalendar} size="lg" />
          </InputLeftElement>
          <DatePicker
            selected={birthdayValue}
            showYearDropdown
            dateFormatCalendar="MMMM"
            maxDate={subtractYearsFromDate(14)}
            onChange={(date: Date) => {
              setBirthdayValue(date);
            }}
          />
          <FormLabel className={birthdayValue ? 'active' : ''}>{t('form.birthdayLabel')}</FormLabel>
        </FormControl>

        <Button type="submit" isLoading={loading} disabled={loading} size="lg" my={6}>
          {isSignUp ? t('submitButtonSignUp') : t('submitButtonUpdate')}
        </Button>

        {formError && (
          <Text id="error" color="pink.500" fontWeight="600">
            {formError}
          </Text>
        )}

        {user.bossQualifications?.commercial &&
          router.pathname.includes('boss-hub') &&
          (!user.homeLocation || !user.instagramHandle) && (
            <Text id="error" color="pink.500" fontWeight="600">
              Please enter instagram handle and home location to be able to create classes and for
              BOSS pages{' '}
              <Text fontSize="12px">
                home location can be a regular studio, your home address or your home city
              </Text>
            </Text>
          )}
      </form>
      {isSignUp && (
        <Button
          onClick={() => dispatch(setAuthModalPage(AUTH_MODAL_PAGES.SIGN_UP_SUCCESS))}
          data-test-id="sign-up-profile-form-skip-button"
          variant="unstyled"
          mt={4}
        >
          {t('skipButton')}
        </Button>
      )}
    </Box>
  );
}
